import React, {useContext, useEffect} from 'react';
import classes from "../Packages/Packages.css";
// import {Buttons} from "../../components/UI/Button/Button";
import {OfferDataContext} from "../../provider/OfferData";
import {Button} from 'antd';
import {BuildControl} from "../../components/BuildControl/BuildControl";
import {ExtraServicesControl} from "../../components/BuildControl/ExtraServicesControl";

export const EditPackage = (props) => {

    const {editablePackage: [packageEdit, setPackageEdit]} = useContext(OfferDataContext);
    const {pricingHook} = props;
    console.log(packageEdit)
    const {sites, languages, secondLang, marketing, maintenance, hosting, content, price} = packageEdit
    const addToPackage = (type) => {
        console.log(marketing)
        switch (type) {
            case 'google':
                let chosenGoogle = marketing.find(item => item.name === type);
                let wordsNoGoogle = chosenGoogle.no + 30;
                let googlePrice = chosenGoogle.price[0] * 30 + price;
                chosenGoogle.no = wordsNoGoogle
                const updateGoogle = {...packageEdit, price: googlePrice, marketing: marketing};
                setPackageEdit(updateGoogle)
                break

            case 'facebook':
                let chosenFb = marketing.find(item => item.name === type);
                let wordsNoFb = chosenFb.no + 2;
                let fbPrice = chosenFb.price[0] * 2 + price;
                chosenFb.no = wordsNoFb
                const updateFb = {...packageEdit, price: fbPrice, marketing: marketing};
                setPackageEdit(updateFb)
                break

            case 'seo':
                let chosenSeo = marketing.find(item => item.name === type);
                let wordsNoSeo = chosenSeo.no + 2;
                let seoPrice = chosenSeo.price[0] * 2 + price;
                chosenSeo.no = wordsNoSeo
                const updateSeo = {...packageEdit, price: seoPrice, marketing: marketing};
                setPackageEdit(updateSeo)
                break

            case 'management social media':
                let chosenSocial = marketing.find(item => item.name === type);
                let wordsNoSocial = chosenSocial.no + 1;
                let socialPrice = chosenSocial.price[0] * 1 + price;
                chosenSocial.no = wordsNoSocial
                const updateSocial = {...packageEdit, price: socialPrice, marketing: marketing};
                setPackageEdit(updateSocial)
                break

            case 'maintenance':
                let mainNo = maintenance + 3;
                let maintenancePrice = 3 * pricingHook.maintenancePriceHook[0] + price;
                const updateMain = {...packageEdit, price: maintenancePrice, maintenance: mainNo};
                setPackageEdit(updateMain)
                break

            case 'hosting':
                let hostingNo = hosting + 1;
                let hostingPrice = 1 * pricingHook.hostingPriceHook[0] + price;
                const updateHosting = {...packageEdit, price: hostingPrice, hosting: hostingNo};
                setPackageEdit(updateHosting)
                break

            case 'content':
                const updateContent = {...packageEdit, price: price + pricingHook.contentPriceHook[0], content: true};
                setPackageEdit(updateContent)
                break

        }
    }
    const removeToPackage = (type) => {
        switch (type) {
            case 'google':
                let chosenGoogle = marketing.find(item => item.name === type);
                let wordsNoGoogle = chosenGoogle.no - 30;
                let googlePrice = price - chosenGoogle.price[0] * 30;
                chosenGoogle.no = wordsNoGoogle
                const updateGoogle = {...packageEdit, price: googlePrice, marketing: marketing};
                setPackageEdit(updateGoogle)
                break
            case 'facebook':
                let chosenFb = marketing.find(item => item.name === type);
                let wordsNoFb = chosenFb.no - 2;
                let fbPrice = price - chosenFb.price[0] * 2;
                chosenFb.no = wordsNoFb
                const updateFb = {...packageEdit, price: fbPrice, marketing: marketing};
                setPackageEdit(updateFb)
                break
            case 'seo':
                let chosenSeo = marketing.find(item => item.name === type);
                let wordsNoSeo = chosenSeo.no - 2;
                let seoPrice = price - chosenSeo.price[0] * 2;
                chosenSeo.no = wordsNoSeo
                const updateSeo = {...packageEdit, price: seoPrice, marketing: marketing};
                setPackageEdit(updateSeo)
                break
            case 'management social media':
                let chosenSocial = marketing.find(item => item.name === type);
                let wordsNoSocial = chosenSocial.no - 1;
                let socialPrice = price - chosenSocial.price[0] * 1;
                chosenSocial.no = wordsNoSocial
                const updateSocial = {...packageEdit, price: socialPrice, marketing: marketing};
                setPackageEdit(updateSocial)
                break
            case 'maintenance':
                let mainNo = maintenance - 3;
                let maintenancePrice = price - 3 * pricingHook.maintenancePriceHook[0];
                const updateMain = {...packageEdit, price: maintenancePrice, maintenance: mainNo};
                setPackageEdit(updateMain)
                break
            case 'hosting':
                let hostingNo = hosting - 1;
                let hostingPrice = price - 1 * pricingHook.hostingPriceHook[0];
                const updateHosting = {...packageEdit, price: hostingPrice, hosting: hostingNo};
                setPackageEdit(updateHosting)
                break

            case 'content':
                const updateContent = {...packageEdit, price: price - pricingHook.contentPriceHook[0], content: false};
                setPackageEdit(updateContent)
                break
        }
    }
    let siteEL = sites && sites.filter(item => item.name !== null).map(item => {
        return <p>{item.name}</p>
    })
    let langEl = languages && languages.filter(item => item.name !== null).map(item => {
        return <p>{item.name}</p>
    })
    let secLang = secondLang.length > 0 ? secondLang.map(item => {
        return <p>{item.name}</p>
    }) : null
    let markEl = marketing && marketing.filter(item => item.name !== null).map(item => {
        let words = null;
        switch (item.name) {
            case 'facebook':
                words = item.no === 1 ? 'postare/luna' : 'postari/luna'
                break
            case 'google':
                words = item.no === 1 ? 'cuvant/luna' : 'cuvinte/luna'
                break
            case 'seo':
                words = item.no === 1 ? 'cuvant' : 'cuvinte'
                break
            case 'management social media':
                words = item.no === 1 ? 'luna' : 'luni'
                break
        }
        return <BuildControl disabled={item.no === 0} add={() => addToPackage(item.name)}
                             remove={() => removeToPackage(item.name)}
                             key={item.name}> {item.name} : {item.no > 0 ? item.no + ' ' + words : 'Nu exista'}
        </BuildControl>

    })

    const style = {
        textAlign: 'center',
        marginLeft: '0',
        padding: '5px'
    }
    let maintenanceEl, hostingEl, contentEl
    if (maintenance && maintenance > 0) {
        maintenanceEl =
            <BuildControl disabled={maintenance < 1} add={() => addToPackage('maintenance')}
                          remove={() => removeToPackage('maintenance')}>
                <h5 style={style}>Mentenanta: <span className={classes.HostingAndMaintenance}>{maintenance} luni</span>
                </h5>
            </BuildControl>
    } else {
        maintenanceEl =
            <BuildControl disabled={maintenance < 1} add={() => addToPackage('maintenance')}
                          remove={() => removeToPackage('maintenance')}>
                <h5 style={style}>Mentenanta: <span className={classes.HostingAndMaintenance}>Nu exista</span></h5>
            </BuildControl>
    }
    if (hosting && hosting > 0) {
        hostingEl = <BuildControl disabled={hosting < 1} add={() => addToPackage('hosting')}
                                  remove={() => removeToPackage('hosting')}>
            <h5 style={style}>Hosting: <span className={classes.HostingAndMaintenance}>{hosting} gb</span></h5>
        </BuildControl>
    } else {
        hostingEl =
            <BuildControl disabled={hosting < 1} add={() => addToPackage('hosting')}
                          remove={() => removeToPackage('hosting')}>
                <h5 style={style}>Hosting: <span className={classes.HostingAndMaintenance}>Nu exista</span></h5>
            </BuildControl>
    }
    if (content) {
        contentEl = <ExtraServicesControl increase={true} decrease={false} add={() => addToPackage('content')}
                                          remove={() => removeToPackage('content')}>
            <h5 style={style}>Continut: <span className={classes.HostingAndMaintenance}>Incarcat!</span></h5>
        </ExtraServicesControl>
    } else {
        contentEl =
            <ExtraServicesControl increase={false} decrease={true} add={() => addToPackage('content')}
                                  remove={() => removeToPackage('content')}>
                <h5
                    style={style}>Continut: <span className={classes.HostingAndMaintenance}>Neincarcat!</span></h5>
            </ExtraServicesControl>
    }
    let packageNoTwo =
        <>
            <h2 className={classes.PricingTable__header}>- PACHET ALES-</h2>
            <h3 className={classes.PricingTable__price}>€ {price}</h3>
            <h5>Site: </h5>
            {siteEL}
            <h5>Limba: </h5>
            {langEl}
            {secLang}
            <h5>Marketing: </h5>
            {markEl}
            {maintenanceEl}
            {hostingEl}
            {contentEl}
        </>

    const nextPageHandler = () => {
        props.history.replace({pathname: '/comentarii'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/alege-pachet'});
    }

    return (
        <>
            <div className={classes.PricingTableWrapper}>
                <div className={classes.PricingTable} style={{width: '400px', maxWidth: '400px',backgroundColor:"#EBF7F9"}} onClick={null}>

                    {packageNoTwo}

                </div>

            </div>
            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} onClick={nextPageHandler}>PAGINA URMATOARE</Button>
            </div>
        </>
    )
}
import React, {useState, useContext} from 'react';
import classes from './ContactData.css';
import {Input} from "../../components/UI/Input/Input";
// import {Button} from '../../components/UI/Button/Button'
import {Button} from 'antd'
import {Redirect} from 'react-router';
import {OfferDataContext} from "../../provider/OfferData";
import {BackgroundVideo} from "../../components/BackgroundVideo/BackgroundVideo";

export const ContactData = (props) => {

    const {selectedContactData: [state, setState]} = useContext(OfferDataContext)
    // console.log(state)
    const inputChangeHandler = (ev) => {
        setState({
            ...state,
            [ev.target.name]: ev.target.value
        });
    }
    const nextPageHandler = () => {
        props.history.replace({pathname: '/alege-site'});
    }

    return (
        <div className={classes.ContactData}>

            <h2>1. Completeaza formularul de mai jos</h2>
            <form>
                <Input type='input'
                       name='firstName'
                       label='Nume'
                       elementConfig={{type: 'text'}}
                       value={state.firstName}
                       changed={(ev) => inputChangeHandler(ev)}
                />
                <Input type='input'
                       name='lastName'
                       label='Prenume'
                       elementConfig={{type: 'text'}}
                       value={state.lastName}
                       changed={inputChangeHandler}
                />
                <Input type='input'
                       name='email'
                       label='Email'
                       elementConfig={{type: 'email'}}
                       value={state.email}
                       changed={inputChangeHandler}
                />
                <Input type='input'
                       name='position'
                       label='Functia'
                       elementConfig={{type: 'text'}}
                       value={state.position}
                       changed={inputChangeHandler}
                />
                <Input type='input'
                       name='phone'
                       label='Telefon'
                       elementConfig={{type: 'number'}}
                       value={state.phone}
                       changed={inputChangeHandler}
                />
                <Input type='input'
                       name='company'
                       label='Companie'
                       elementConfig={{type: 'text'}}
                       value={state.company}
                       changed={inputChangeHandler}
                />
                <Input type='input'
                       name='employeeNo'
                       label='Nr. angajati'
                       elementConfig={{type: 'number'}}
                       value={state.employeeNo}
                       changed={inputChangeHandler}
                />
                <Input type='input'
                       name='industry'
                       label='Industrie'
                       elementConfig={{type: 'text'}}
                       value={state.industry}
                       changed={inputChangeHandler}
                />
            </form>
            {/*<button style={{display: 'block'}} onClick={toSitePageHandler}>Pagina urmatoare</button>*/}
            <div className={classes.Pagination}>
                <Button status={false} onClick={nextPageHandler}>PAGINA URMATOARE</Button>
            </div>
        </div>
    )
}
// const [firstName, setFirstName] = useState(null);
// const [lastName, setLastName] = useState(null);
// const [email, setEmail] = useState(null);
// const [position, setPosition] = useState(null);
// const [phone, setPhone] = useState(null);
// const [company, setCompany] = useState(null);
// const [employeeNo, setEmployeeNo] = useState(null);
// const [industry, setIndustry] = useState(null);
import React from 'react';
import {Button} from 'antd';
import classes from './BuildControl.css'

export const ExtraServicesControl = (props) => {

    return (
        <div className={classes.BuildControl}>
            <Button disabled={props.increase ? false : true} danger
                    onClick={props.remove}
                    style={{marginLeft: '50px', backgroundColor: !props.increase ? '#ff999b' : null}}
                    type="primary">-</Button>

            {props.children}


            <Button onClick={props.add} disabled={props.increase ? true : false}
                    style={{marginRight: '50px', backgroundColor: props.increase ? '#b3daff' : null}}
                    type="primary">+</Button>
        </div>
    )
}
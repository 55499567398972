import React, {useContext} from 'react';
import classes from './Site.css';
import shopImg from '../../assets/shop.png';
import marketingImg from '../../assets/marketing.png';
import presentationImg from '../../assets/presentation.png';
import digitalImg from '../../assets/digitalmedia.png';
import {Input} from "../../components/UI/Input/Input";
// import {Button} from "../../components/UI/Button/Button";


import {Button} from 'antd'

import {OfferDataContext} from "../../provider/OfferData";

export const Site = (props) => {
    const {selectedSite: [state, setState]} = useContext(OfferDataContext)

    const inputChangeHandler = (ev, label) => {
        setState({
            ...state,
            [ev]: state[ev] ? null : label
        });
    }
    console.log(state)
    let cssSiteChecked1 = classes.SiteSelectedDefault;
    let cssSiteChecked2 = classes.SiteSelectedDefault;
    let cssSiteChecked3 = classes.SiteSelectedDefault;
    let cssSiteChecked4 = classes.SiteSelectedDefault;
    if (state.presentationalWebsite) {
        cssSiteChecked1 = classes.SiteSelected;
    }
    if (state.corporateWebsite) {
        cssSiteChecked2 = classes.SiteSelected;
    }
    if (state.shopWebsite) {
        cssSiteChecked3 = classes.SiteSelected;
    }
    if (state.customWebsite) {
        cssSiteChecked4 = classes.SiteSelected;
    }


    const nextPageHandler = () => {
        props.history.replace({pathname: '/alege-limba'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/'});

    }
    return (
        <div className={classes.Site}>
            <h2>2. Doresti un website?</h2>
            <form>

                <div onClick={() => inputChangeHandler('presentationalWebsite', 'Site prezentare')}
                     className={[classes.LabelDiv, cssSiteChecked1].join(' ')}>
                    <img src={digitalImg} alt={'photo is not loaded'}/>
                    <p>Site prezentare</p>
                </div>


                <div onClick={() => inputChangeHandler('corporateWebsite', 'Site corporativ')}
                     className={[classes.LabelDiv, cssSiteChecked2].join(' ')}>
                    <img src={presentationImg}/>
                    <p>Site corporativ</p>
                </div>


                <div onClick={() => inputChangeHandler('shopWebsite', 'Shop online')}
                     className={[classes.LabelDiv, cssSiteChecked3].join(' ')}>
                    <img src={shopImg}/>
                    <p>Shop online</p>


                </div>

                <div>
                    <div onClick={() => inputChangeHandler('customWebsite', 'Site custom')}
                         className={[classes.LabelDiv, cssSiteChecked4].join(' ')}>
                        <img src={marketingImg}/>
                        <p>Site custom</p>
                    </div>
                </div>
            </form>
            {/*<button style={{display: 'block'}} onClick={toSitePageHandler}>Pagina urmatoare</button>*/}
            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} onClick={nextPageHandler}>PAGINA URMATOARE</Button>
            </div>
        </div>
    )
}
// const [firstName, setFirstName] = useState(null);
// const [lastName, setLastName] = useState(null);
// const [email, setEmail] = useState(null);
// const [position, setPosition] = useState(null);
// const [phone, setPhone] = useState(null);
// const [company, setCompany] = useState(null);
// const [employeeNo, setEmployeeNo] = useState(null);
// const [industry, setIndustry] = useState(null);
import React from 'react';
import classes from './NavigationItems.css';
export const NavigationItems = (props) => {
    return (
        <div className={classes.NavigationItems}>
           <a>ACASA</a>
           <a>DEPSRE NOI</a>
           <a>SERVICII</a>
           <a>LUCRARI</a>
           <a>BLOG</a>
           <a>CONTACT</a>
        </div>
    )
}
import React, {useContext} from 'react';
import classes from './../Site/Site.css';
import right from '../../assets/green-check.jpg';
import wrong from '../../assets/negative-2.png';
import {Button} from "antd";
import {OfferDataContext} from "../../provider/OfferData";

export const Hosting = (props) => {
    const {selectedHosting: [state, setState]} = useContext(OfferDataContext)
    const inputChangeHandler = (ev) => {
        setState(ev);
    }
    let cssSiteChecked1 = classes.SiteSelectedDefault;
    let cssSiteChecked2 = classes.SiteSelectedDefault;
    if (state) {
        cssSiteChecked1 = classes.SiteSelected;
    } else if (state === false) {
        cssSiteChecked2 = classes.SiteSelected;
    }
    const nextPageHandler = () => {
        props.history.replace({pathname: '/alege-pachet'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/alege-mentenanta'});
    }
    console.log(state)
    return (
        <div className={classes.Site}>
            <h2>6. Doresti hosting?</h2>
            <form style={{justifyContent:'space-evenly'}}>
                <div>
                    <div onClick={() => inputChangeHandler(2)}
                         className={[classes.LabelDiv, cssSiteChecked1].join(' ')}>
                        <img src={right} alt={'photo is not loaded'}/>
                        <p>Da</p>
                    </div>
                </div>
                <div>
                    <div onClick={() => inputChangeHandler(false)}
                         className={[classes.LabelDiv, cssSiteChecked2].join(' ')}>
                        <img src={wrong}/>
                        <p>Nu</p>
                    </div>
                </div>
            </form>
            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} onClick={nextPageHandler}>PAGINA URMATOARE</Button>
            </div>
        </div>
    )
}

import React, {useContext} from 'react';
import classes from './Language.css'
import Ro from './../../assets/romania-flag.jpg'
import De from './../../assets/germanyflag-200x200.jpg'
import En from './../../assets/uk-2-flag.jpg'
import {Select,Button} from 'antd';
import {OfferDataContext} from "../../provider/OfferData";

const {Option} = Select;

export const Language = (props) => {
    const {selectedLanguage: [state, setState]} = useContext(OfferDataContext)
    const {selectedOtherLanguage: [otherLang, setOtherLang]} = useContext(OfferDataContext)

    const langChooseHandler = (ev, label) => {
        setState({
            ...state,
            [ev]: state[ev] ? null : label
        })
    }
    const addOtherLanguageHandler = (lang) => {
        console.log(lang)
        setOtherLang(lang)
    }
    console.log(state)
    const nextPageHandler = () => {
        props.history.replace({pathname: '/alege-marketing'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/alege-site'});

    }
    const children = [
        <Option key={'franceza'} value={'franceza'}>fr</Option>,
        <Option key={'maghiara'} value={'maghiara'}>hu</Option>,
        <Option key={'spaniola'} value={'spaniola'}>sp</Option>,
        <Option key={'italiana'} value={'italiana'}>it</Option>,
        // <Option key={'other'}>alta</Option>,
    ];
    console.log(otherLang)
    return (
        <div className={classes.Language}>
            <h2>3. Alege limba</h2>
            <form>
                <div className={state.romanian ? classes.Selected : classes.NotSelected}
                     onClick={() => langChooseHandler('romanian', 'romana')}>
                    <img src={Ro}/>
                    <p>Romana</p>
                </div>
                <div className={state.english ? classes.Selected : classes.NotSelected}
                     onClick={() => langChooseHandler('english', 'engleza')}>
                    <img src={En}/>
                    <p>Engleza</p>
                </div>
                <div className={state.german ? classes.Selected : classes.NotSelected}
                     onClick={() => langChooseHandler('german', 'germana')}>
                    <img src={De}/>
                    <p>Germana</p>
                </div>
                <div
                    className={[classes.QustionMark, otherLang.length > 0 ? classes.Selected : classes.NotSelected].join(' ')}>
                    <div style={{
                        width: '150px',
                        height: '175px',
                        textAlign: 'center',
                        alignItems: 'center',
                        fontSize: '30px',
                        display: 'flex'
                    }}>
                        Doresti o alta limba?
                    </div>
                    <Select
                        defaultValue={otherLang}
                        mode="multiple"
                        allowClear
                        style={{width: '100%', maxWidth: '200px'}}
                        placeholder="Alege o limba"
                        onChange={addOtherLanguageHandler}>
                        {children}
                    </Select>
                </div>
            </form>
            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} onClick={nextPageHandler}> PAGINA URMATOARE</Button>
            </div>
        </div>
    )
}
import React, {useState} from 'react';
import classes from './Input.css';

export const Input = (props) => {

    const [label, setLabel] = useState(Math.random().toString(36).substring(7));
    let input = null;
    let errors = '';
    if (props.value === '') {
        errors = <p>Camp obligatoriu</p>
    }
    if (props.name === 'phone' && (props.value.length < 10 || props.value.length > 10)) {
        errors = <p>Trebuie sa contina 10 cifre. </p>
    }
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (props.elementConfig.type === 'email' && !regEmail.test(props.value)) {
        errors = <p>Trebuie introdus un email valid. </p>
    }

    switch (props.type) {
        case 'input':
            if (props.elementConfig.type === 'text' || props.elementConfig.type === 'email' || props.elementConfig.type === 'number') {
                input = <input id={label} onChange={props.changed} name={props.name}  {...props.elementConfig}
                               value={props.value}/>;
                // cssClassAdded = inputClasses.InputText;
            }
            if (props.elementConfig.type === 'checkbox') {
                input = <input id={label} onChange={props.changed} name={props.name}  {...props.elementConfig}
                               checked={props.value} defaultChecked={false}/>;
                // cssClassAdded = checkboxClasses.Checkbox;
            }
            if (props.elementConfig.type === 'radio') {
                input = <input id={label} name={props.name} onChange={props.changed}
                               {...props.elementConfig}
                               checked={props.value}/>;
                // cssClassAdded = radioClasses.Radio;
            }
    }
// console.log(props.elementConfig.type)
    return (
        <div className={classes.Input}>
            <label htmlFor={label}>
                {props.label}
                {props.elementConfig.type === 'text' || props.elementConfig.type === 'email' || props.elementConfig.type === 'number' ?
                    <span> *</span> : null}
            </label>
            {input}
            {errors}
        </div>
    )
}

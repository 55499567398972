import React, {createContext, useState} from 'react';

export const PricingDataContext = createContext({});
export const PricingDataProvider = (props) => {
    let priceHook = useState(0)
    let presentationPriceHook = useState(500)
    let corporateWebsitePriceHook = useState(700)
    let shopWebsitePriceHook = useState(1100)
    let customWebsitePriceHook = useState(9750)
    let langPriceHook = useState(250)
    let marketingPriceHook = useState(null)
    let facebookPriceHook = useState(20)
    let seoPriceHook = useState(23)
    let googlePriceHook = useState(12)
    let socialMediaPriceHook = useState(300)
    let contentPriceHook = useState(150)
    let hostingPriceHook = useState(50)
    let maintenancePriceHook = useState(20)
    const value = {
        presentationPriceHook, corporateWebsitePriceHook, shopWebsitePriceHook, customWebsitePriceHook, langPriceHook,
        marketingPriceHook,
        facebookPriceHook,
        seoPriceHook,
        googlePriceHook,
        socialMediaPriceHook,
        contentPriceHook,
        hostingPriceHook,
        maintenancePriceHook,
        priceHook
    }
    return (
        <PricingDataContext.Provider value={value}>
            {props.children}
        </PricingDataContext.Provider>
    )
}

import React, {useEffect} from 'react';
import classes from "../Packages.css";

export const Package = (props) => {
    const {
        name,
        price,
        clicked,
        isSelected,
        sites,
        languages,
        secondLang,
        marketing,
        maintenance,
        hosting,
        content, facebookNo, seoNo, googleNo
    } = props;
    // console.log(isSelected)
    let renderHooks = (hooks) => {
        return hooks && hooks.map(item => {
            if (item.name) {
                switch (item.name) {
                    case 'facebook':
                        return <p key={item.name}
                                  className={classes.List}>{item.name}: {facebookNo} postari/luna </p>
                    case 'google':
                        return <p key={item.name}
                                  className={classes.List}>{item.name}: {googleNo} cuvinte/luna</p>
                    case 'seo':
                        return <p key={item.name}
                                  className={classes.List}>{item.name}: {seoNo} cuvinte</p>
                    case 'socialNetManagement':
                        return <p key={item.name} className={classes.List}>{item.name}</p>
                }
                return <p key={item.name} className={classes.List}>{item.name}</p>
            }
        })
    }
    let site = renderHooks(sites),
        lang = renderHooks(languages),
        secLang = renderHooks(secondLang),
        mark = renderHooks(marketing),
        maintain = maintenance,
        host = hosting,
        hostingEl = null,
        maintenanceEl = null,
        contentEl = null

    // console.log(secondLang)
    let siteEl = site && site.filter(item => item !== undefined).length > 0 ? <>
        <div><span>{site}</span></div>
    </> : <p>Nu ati selectat nimic</p>


    let langEl = lang && lang.filter(item => item !== undefined).length < 1 && secondLang.filter(item => item !== undefined).length < 1 ? <>
        <p>Nu ati selectat nimic</p>
    </> : <div><span>{lang}</span><span>{secLang}</span></div>
    // console.log(mark)
    let markEl = mark && mark.filter(item => item !== undefined).length > 0 ?
        <div><span>{mark}</span></div>
        : <p>Nu ati selectat nimic</p>

    if (maintain) {
        maintenanceEl = <h5>Mentenanta: <span className={classes.HostingAndMaintenance}>{maintain} luni</span></h5>
    } else {
        maintenanceEl = <h5>Mentenanta: <span className={classes.HostingAndMaintenance}>Nu exista</span></h5>
    }
    if (host) {
        hostingEl = <h5>Hosting: <span className={classes.HostingAndMaintenance}>{host} gb</span></h5>
    } else {
        hostingEl = <h5>Hosting: <span className={classes.HostingAndMaintenance}>Nu exista</span></h5>
    }
    if (content) {
        contentEl = <h5>Continut: <span className={classes.HostingAndMaintenance}>Incarcat!</span></h5>
    } else {
        contentEl = <h5>Continut: <span className={classes.HostingAndMaintenance}>Neincarcat!</span></h5>
    }
    let packageNoOne = name === 'PACHET 1' ?
        <>
            <h2 className={classes.PricingTable__header}>- {name}-</h2>
            <h3 className={classes.PricingTable__price}>€ {price}</h3>
            <h5>Site: </h5>
            {siteEl}
            <h5>Limba: </h5>
            {langEl}
            <h5>Marketing: </h5>
            {markEl}
            {maintenanceEl}
            {hostingEl}
            {contentEl}
        </> : null
    let packageNoTwo = name === 'PACHET 2' ?
        <>
            <h2 className={classes.PricingTable__header}>- {name}-</h2>
            <h3 className={classes.PricingTable__price}>€ {price}</h3>
            <h5>Site: </h5>
            {siteEl}
            <h5>Limba: </h5>
            {langEl}
            <h5>Marketing: </h5>
            {markEl}
            {maintenanceEl}
            {hostingEl}
            {contentEl}
        </> : null
    let packageNoThree = name === 'PACHET 3' ?
        <>
            <h2 className={classes.PricingTable__header}>- {name}-</h2>
            <h3 className={classes.PricingTable__price}>€ {price}</h3>
            <h5>Site: </h5>
            {siteEl}
            <h5>Limba: </h5>
            {langEl}
            <h5>Marketing: </h5>
            {markEl}
            {maintenanceEl}
            {hostingEl}
            {contentEl}
        </> : null

    return (
        <div className={isSelected ? classes.PricingTableSelected : classes.PricingTable} onClick={clicked}>
            {packageNoOne}
            {packageNoTwo}
            {packageNoThree}
        </div>
    )
}
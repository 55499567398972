import React, {useContext} from 'react';
import classes from './../Site/Site.css';
import facebook from '../../assets/facebook.png';
import google from '../../assets/google.png';
import seo from '../../assets/seo.png';
import socialNetMana from '../../assets/digitalmedia.png';
import {Input} from "../../components/UI/Input/Input";
import {Button} from "antd";
import {OfferDataContext} from "../../provider/OfferData";

export const Marketing = (props) => {
    const {selectedMarketing: [state, setState]} = useContext(OfferDataContext)

    const inputChangeHandler = (ev, label) => {
        setState({
            ...state,
            [ev]: state[ev] ? null : label
        });
    }
    let cssSiteChecked1 = classes.SiteSelectedDefault;
    let cssSiteChecked2 = classes.SiteSelectedDefault;
    let cssSiteChecked3 = classes.SiteSelectedDefault;
    let cssSiteChecked4 = classes.SiteSelectedDefault;
    if (state.facebook) {
        cssSiteChecked1 = classes.SiteSelected;
    }
    if (state.google) {
        cssSiteChecked2 = classes.SiteSelected;
    }
    if (state.seo) {
        cssSiteChecked3 = classes.SiteSelected;
    }
    if (state.socialNetManagement) {
        cssSiteChecked4 = classes.SiteSelected;
    }

    const nextPageHandler = () => {
        props.history.replace({pathname: '/alege-mentenanta'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/alege-limba'});
    }
    console.log(state)
    return (
        <div className={classes.Site}>
            <h2>4. Alege marketing</h2>
            <form>
                <div>
                    <div onClick={() => inputChangeHandler('facebook', 'facebook')}
                         className={[classes.LabelDiv, cssSiteChecked1].join(' ')}>
                        <img src={facebook} alt={'photo is not loaded'}/>
                        <p>Facebook ads</p>
                    </div>
                </div>
                <div>
                    <div onClick={() => inputChangeHandler('google', 'google')}
                         className={[classes.LabelDiv, cssSiteChecked2].join(' ')}>
                        <img src={google}/>
                        <p>Google ads</p>


                    </div>
                </div>
                <div>
                    <div onClick={() => inputChangeHandler('seo', 'seo')}
                         className={[classes.LabelDiv, cssSiteChecked3].join(' ')}>
                        <img src={seo}/>
                        <p>Seo</p>
                    </div>
                </div>
                <div>
                    <div onClick={() => inputChangeHandler('socialNetManagement', 'management social media')}
                         className={[classes.LabelDiv, cssSiteChecked4].join(' ')}>
                        <img src={socialNetMana}/>
                        <p>Social Management</p>
                    </div>
                </div>

            </form>
            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} onClick={nextPageHandler}>PAGINA URMATOARE</Button>
            </div>
        </div>
    )
}

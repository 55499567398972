import React from 'react';
import {Toolbar} from "../../components/Navigation/Toolbar/Toolbar";
import {Footer} from "../../components/Footer/Footer";
import classes from './Layouts.css';
import {CurrentStep} from "../../components/UI/CurrentStep/CurrentStep";
import {BackgroundVideo} from "../../components/BackgroundVideo/BackgroundVideo";

export const Layouts = (props) => {
    return (
        <div className={classes.Layouts}>
            <Toolbar/>
            <BackgroundVideo/>

            <CurrentStep/>
            <main className={classes.Main}>
                {props.children}
            </main>
            <Footer/>
        </div>
    )
}

import React, {createContext, useState} from 'react';

export const OfferDataContext = createContext({});
export const OfferDataProvider = (props) => {
    const selectedContactData = useState({
        firstName: '',
        lastName: '',
        email: '',
        position: '',
        phone: '',
        company: '',
        employeeNo: '',
        industry: '',
    })
    const contactDataValidity = useState({
        isValid: true

    })
    const selectedSite = useState({
        presentationalWebsite: null,
        corporateWebsite: null,
        shopWebsite: null,
        customWebsite: null,
    })
    const selectedLanguage = useState({
        romanian: null,
        german: null,
        english: null,
        // other: []
    })
    const selectedOtherLanguage = useState([])

    const selectedMarketing = useState({
        facebook: null,
        google: null,
        seo: null,
        socialNetManagement: null,
    })
    const selectedMaintenance = useState(null)
    const selectedHosting = useState(null)

    const selectedPackage = useState({
        packageOne: false,
        packageTwo: true,
        packageThree: false
    })
    const editablePackage = useState({
        // packagesTwo: {
        sites: null,
        languages: null,
        secondLang: [],
        price: null,
        marketing: null,
        maintenance: null,
        hosting: null,
        content: null,

    })
    const value = {
        selectedContactData,
        selectedSite,
        selectedLanguage,
        selectedOtherLanguage,
        selectedMarketing,
        selectedMaintenance,
        selectedHosting,
        selectedPackage,
        editablePackage,
        contactDataValidity
    }
    return (
        <OfferDataContext.Provider value={value}>
            {props.children}
        </OfferDataContext.Provider>
    )
}

import React from 'react';
import {NavigationItems} from "../NavigationItems/NavigationItems";
import classes from './Toolbar.css';
import {Logo} from "../../UI/Logo/Logo";

export const Toolbar = (props) => {
    return (
        <>
            <header className={classes.Toolbar}>
                <div className={classes.Width}>
                    <Logo/>
                    <NavigationItems/>
                </div>
            </header>
        </>
    )
}
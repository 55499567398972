import {Layouts} from "./hoc/Layouts/Layouts";
import React, {useEffect} from 'react'
import {ContactData} from "./containers/ContactData/ContactData";
import {CurrentStep} from "./components/UI/CurrentStep/CurrentStep";
import {Switch, Route} from "react-router-dom";
import {Site} from "./containers/Site/Site";
import {Language} from "./containers/Language/Language";
import {Marketing} from "./containers/Marketing/Marketing";
import {Maintenance} from "./containers/Maintenance/Maintenance";
import {OfferDataContext, OfferDataProvider} from "./provider/OfferData";
import {Hosting} from "./containers/Hosting/Hosting";
import {Packages} from "./containers/Packages/Packages";
import {PricingDataProvider, PricingDataContext} from "./provider/PricingData";
import {EditPackage} from "./containers/EditPackage/EditPackage";
import {Comments} from "./containers/Comments/Comments";

const App = (props) => {
    return (
        <div>
            <Switch>
                <OfferDataProvider>
                    <OfferDataContext>
                        {value =>
                            (<Layouts>
                                    <PricingDataProvider>
                                        <PricingDataContext>
                                            {val => (
                                                <>
                                                    <Route path='/comentarii' exact
                                                           render={routeProps => <Comments hookData={value}
                                                                                           pricingHook={val} {...props} {...routeProps} />}/>
                                                    <Route path='/modifica-pachet' exact
                                                           render={routeProps => <EditPackage hookData={value}
                                                                                              pricingHook={val} {...props} {...routeProps} />}/>
                                                    <Route path='/alege-pachet' exact
                                                           render={routeProps => <Packages hookData={value}
                                                                                           pricingHook={val} {...props} {...routeProps}/>}/>
                                                </>
                                            )}
                                        </PricingDataContext>
                                    </PricingDataProvider>
                                    <Route path='/alege-hosting' exact component={Hosting}/>
                                    <Route path='/alege-mentenanta' exact component={Maintenance}/>
                                    <Route path='/alege-marketing' exact component={Marketing}/>
                                    <Route path='/alege-limba' exact component={Language}/>
                                    <Route path='/alege-site' exact component={Site}/>
                                    <Route path='/' exact component={ContactData}/>

                                </Layouts>
                            )}
                    </OfferDataContext>
                </OfferDataProvider>

            </Switch>
        </div>

    );
}
export default App;

import React from 'react';
import classes from './Footer.css';
export const Footer = (props) => {
    return (
        <div className={classes.Footer}>
           <footer>
               <main>
                    <div>Link-uri utile</div>
                    <div>Noutati pe blog</div>
                    <div>Date contact</div>
               </main>
           </footer>
        </div>
    )
}

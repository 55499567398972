import {Steps, Divider} from 'antd';
import React, {useState, useEffect} from 'react'
import classes from './CurrentStep.css'
import {
    FORM_COMPLETE,
    CHOOSE_SITE,
    CHOOSE_LANGUAGE,
    CHOOSE_MARKETING,
    CHOOSE_MAINTENANCE,
    CHOOSE_HOSTING,
    CHOOSE_PACK,
    MODIFY_PACK,
    COMMENTS
} from '../../../constants/CurrentPage/CurrentPage'

const {Step} = Steps;
export const CurrentStep = (props) => {
    const [current, setCurrent] = useState(0);
    let currentPage = window.location.pathname;
    let page = null
    switch (currentPage) {
        case FORM_COMPLETE:
            page = 0;
            break
        case CHOOSE_SITE:
            page = 1;
            break
        case CHOOSE_LANGUAGE:
            page = 2;
            break
        case CHOOSE_MARKETING:
            page = 3;
            break
        case CHOOSE_MAINTENANCE:
            page = 4;
            break
        case CHOOSE_HOSTING:
            page = 5;
            break
        case CHOOSE_PACK:
            page = 6;
            break
        case MODIFY_PACK:
            page = 7;
            break
        case COMMENTS:
            page = 8;
            break
    }
    useEffect(() => {
        setCurrent(page)
    }, [currentPage])
    return (
        <div className={classes.CurrentSteps}>
            <Steps size={window.innerWidth > 1500 ? 'default' : 'small'}
                   style={{width: '80%', margin: '10px auto', color: 'red'}}
                   current={current}>
                <Step color={'red'} style={{color: 'red'}} title="Formular"/>
                <Step title="Site"/>
                <Step title="Limba"/>
                <Step title="Marketing"/>
                <Step title="Mentenanta"/>
                <Step title="Hosting"/>
                <Step title="Pachet"/>
                <Step title="Modifica"/>
                <Step title="Comentarii"/>
            </Steps>
            <Divider/>
        </div>
    )
}


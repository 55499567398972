import React, {useState, useEffect, useContext,} from 'react';
import classes from './Packages.css';
import {Package} from "./Package/Package";
import {OfferDataContext} from "../../provider/OfferData";
import {Button} from "antd";

const FACEBOOK_NO = 4;
const GOOGLE_NO = 30;
const SEO_NO = 6;
const SOCIAL_MANAGEMENT_NO = 1;
const MAINTENANCE_EXTRA = 3;
const HOSTING_EXTRA = 5;
export const Packages = (props) => {
    const {selectedPackage: [chosenPackage, setChosenPackage]} = useContext(OfferDataContext);
    const {editablePackage: [packageEdit, setPackageEdit]} = useContext(OfferDataContext);
    const {hookData} = props;
    const {pricingHook} = props;
    const [packageOne, setPackageOne] = useState({
        sites: null,
        languages: null,
        secondLang: null,
        price: null,
        marketing: null,
        maintenance: null,
        hosting: null,
        content: false,
    })
    const [packageTwo, setPackageTwo] = useState({
        sites: null,
        languages: null,
        secondLang: null,
        price: null,
        marketing: null,
        maintenance: hookData.selectedMaintenance[0],
        hosting: hookData.selectedHosting[0],
        content: pricingHook.contentPriceHook[0]
    })
    const [packageThree, setPackageThree] = useState({
        sites: null,
        languages: null,
        secondLang: null,
        price: null,
        marketing: null,
        maintenance: hookData.selectedMaintenance[0] + MAINTENANCE_EXTRA,
        hosting: hookData.selectedHosting[0] + HOSTING_EXTRA,
        content: pricingHook.contentPriceHook[0],

    })

    useEffect(() => {
        let marketingObjExist = Object.values(hookData.selectedMarketing[0]).filter(item => item !== null).length < 1
        let priceOne, priceTwo, priceThree = null;
        let price = calculatePrice(sites).concat(calculatePrice(languages), calculatePrice(secondLang), calculatePrice(marketing))
            .reduce(
                (total, current) =>
                    total + current,
                0);
        let marketingPackThreeDefault = [
            // {
            //     name: 'facebook',
            //     price: pricingHook.facebookPriceHook,
            //     no: FACEBOOK_NO
            // },
            {
                name: 'google',
                price: pricingHook.googlePriceHook,
                no: GOOGLE_NO
            }]

        priceOne = price;
        priceTwo = price;
        priceThree = marketingObjExist ? calculatePrice(marketingPackThreeDefault).reduce((total, current) => total + current + price, 0) : price;
        console.log(priceThree)
        priceOne = packageOne.maintenance ? priceOne += packageOne.maintenance * pricingHook.maintenancePriceHook[0] : priceOne
        priceOne = packageOne.hosting ? priceOne += packageOne.hosting * pricingHook.hostingPriceHook[0] : priceOne
        priceOne = packageOne.hosting ? priceOne += packageOne.hosting * pricingHook.hostingPriceHook[0] : priceOne

        priceTwo = packageTwo.maintenance ? priceTwo += packageTwo.maintenance * pricingHook.maintenancePriceHook[0] : priceTwo
        priceTwo = packageTwo.hosting ? priceTwo += packageTwo.hosting * pricingHook.hostingPriceHook[0] : priceTwo
        priceTwo = packageTwo.content ? priceTwo += packageTwo.content : priceTwo

        priceThree = packageThree.maintenance ? priceThree += packageThree.maintenance * pricingHook.maintenancePriceHook[0] : priceThree
        priceThree = packageThree.hosting ? priceThree += packageThree.hosting * pricingHook.hostingPriceHook[0] : priceThree
        priceThree = packageThree.content ? priceThree += packageThree.content : priceThree
        // console.log(priceThree)
        setPackageOne({
            ...packageOne, marketing: marketing, price: priceOne, languages, secondLang: secondLang, sites: sites
        })
        setPackageTwo({
            ...packageTwo, marketing: marketing, price: priceTwo, languages, secondLang: secondLang, sites: sites
        })
        setPackageThree({
            ...packageThree,
            marketing: marketingObjExist ? marketingPackThreeDefault : marketing,
            price: priceThree,
            languages,
            secondLang: secondLang,
            sites: sites,
            maintenance: hookData.selectedMaintenance[0] + MAINTENANCE_EXTRA,
            hosting: hookData.selectedHosting[0] + HOSTING_EXTRA,
            content: pricingHook.contentPriceHook[0]
        })
        //must refactoring
        if (chosenPackage.packageOne) {
            setPackageEdit({
                ...packageOne,
                sites: sites, marketing: marketing, price: priceOne, languages: languages, secondLang: secondLang,
            })
        } else if (chosenPackage.packageTwo) {
            setPackageEdit({
                ...packageTwo,
                sites: sites,
                marketing: marketing,
                price: priceTwo,
                languages: languages, secondLang: secondLang,
                maintenance: hookData.selectedMaintenance[0],
                hosting: hookData.selectedHosting[0],
                content: pricingHook.contentPriceHook[0],
            })
        } else {
            setPackageEdit({
                ...packageThree,
                sites: sites,
                marketing: marketingObjExist ? marketingPackThreeDefault : marketing, price: priceThree,
                maintenance: hookData.selectedMaintenance[0] + MAINTENANCE_EXTRA,
                hosting: hookData.selectedHosting[0] + HOSTING_EXTRA,
                content: pricingHook.contentPriceHook[0], languages: languages, secondLang: secondLang,
            })
        }
    }, [])
    console.log(packageOne)
    console.log(packageTwo)
    console.log(packageThree)
    const changePackageHandler = (label) => {
        switch (label) {
            case 'packageOne':
                console.log(packageOne)
                setPackageEdit({
                    sites: packageOne.sites,
                    languages: packageOne.languages,
                    secondLang: packageOne.secondLang,
                    price: packageOne.price,
                    marketing: packageOne.marketing,
                    maintenance: packageOne.maintenance,
                    hosting: packageOne.hosting,
                    content: packageOne.content,
                })

                setChosenPackage({
                    packageOne: true,
                    packageTwo: false,
                    packageThree: false
                })
                break
            case 'packageTwo':
                setPackageEdit({
                    sites: packageTwo.sites,
                    languages: packageTwo.languages,
                    secondLang: packageTwo.secondLang,
                    price: packageTwo.price,
                    marketing: packageTwo.marketing,
                    maintenance: packageTwo.maintenance,
                    hosting: packageTwo.hosting,
                    content: packageTwo.content,
                })
                console.log(packageTwo)

                setChosenPackage({
                    packageOne: false,
                    packageTwo: true,
                    packageThree: false
                })
                break
            case 'packageThree':
                setPackageEdit({
                    sites: packageThree.sites,
                    languages: packageThree.languages,
                    secondLang: packageThree.secondLang,
                    price: packageThree.price,
                    marketing: packageThree.marketing,
                    maintenance: packageThree.maintenance,
                    hosting: packageThree.hosting,
                    content: packageThree.content,
                })
                console.log(packageThree)

                setChosenPackage({
                    packageOne: false,
                    packageTwo: false,
                    packageThree: true
                })
        }
    }

    const hooksDataHandler = (hook) => {
        return Object.keys(hook).map(item => {
            switch (item) {
                case 'corporateWebsite':
                    return {
                        name: hook[item],
                        price: pricingHook.corporateWebsitePriceHook,
                        no: null
                    }
                case 'presentationalWebsite':
                    return {
                        name: hook[item],
                        price: pricingHook.presentationPriceHook,
                        no: null
                    }
                case 'shopWebsite':
                    return {
                        name: hook[item],
                        price: pricingHook.shopWebsitePriceHook,
                        no: null
                    }
                case 'customWebsite':
                    return {
                        name: hook[item],
                        price: pricingHook.customWebsitePriceHook,
                        no: null
                    }
                case 'seo':
                    return {
                        name: hook[item],
                        price: pricingHook.seoPriceHook,
                        no: SEO_NO
                    }
                case 'facebook':
                    return {
                        name: hook[item],
                        price: pricingHook.facebookPriceHook,
                        no: FACEBOOK_NO
                    }
                case 'google':
                    return {
                        name: hook[item],
                        price: pricingHook.googlePriceHook,
                        no: GOOGLE_NO
                    }
                case 'socialNetManagement':
                    return {
                        name: hook[item],
                        price: pricingHook.socialMediaPriceHook,
                        no: SOCIAL_MANAGEMENT_NO
                    }
            }
            return {
                name: hook[item],
                price: pricingHook.langPriceHook,
                no: null
            }
        })
    }
    let sites = hooksDataHandler(hookData.selectedSite[0]).filter(Boolean),
        languages = hooksDataHandler(hookData.selectedLanguage[0]).filter(Boolean),
        secondLang = hooksDataHandler(hookData.selectedOtherLanguage[0]).filter(Boolean),
        marketing = hooksDataHandler(hookData.selectedMarketing[0]).filter(Boolean)

    const calculatePrice = (type) => {
        return type.filter(item => item.name !== null).map(item => {
            return item.no ? item.price[0] * item.no : item.price[0];
        })
    }
    const nextPageHandler = () => {
        props.history.replace({pathname: '/modifica-pachet'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/alege-hosting'});
    }
    console.log(packageEdit)

    return (
        <>
            <div className={classes.PricingTableWrapper}>
                <Package name={'PACHET 1'} sites={sites} languages={languages}
                         secondLang={secondLang} facebookNo={FACEBOOK_NO} googleNo={GOOGLE_NO} seoNo={SEO_NO}
                         marketing={packageOne.marketing}
                         maintenance={packageOne.maintenance} hosting={packageOne.hosting} price={packageOne.price}
                         isSelected={chosenPackage.packageOne} content={packageOne.content}
                         clicked={() => changePackageHandler('packageOne')}/>

                <Package name={'PACHET 2'} sites={sites} languages={languages}
                         secondLang={secondLang} facebookNo={FACEBOOK_NO} googleNo={GOOGLE_NO} seoNo={SEO_NO}
                         marketing={packageTwo.marketing}
                         maintenance={packageTwo.maintenance} hosting={packageTwo.hosting} price={packageTwo.price}
                         isSelected={chosenPackage.packageTwo} content={packageTwo.content}
                         clicked={() => changePackageHandler('packageTwo')}/>

                <Package name={'PACHET 3'} sites={sites} languages={languages}
                         secondLang={secondLang} facebookNo={FACEBOOK_NO} googleNo={GOOGLE_NO} seoNo={SEO_NO}
                         marketing={packageThree.marketing}
                         maintenance={packageThree.maintenance} hosting={packageThree.hosting}
                         price={packageThree.price}
                         isSelected={chosenPackage.packageThree} content={packageThree.content}
                         clicked={() => changePackageHandler('packageThree')}/>
            </div>
            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} onClick={nextPageHandler}>PAGINA URMATOARE</Button>
            </div>
        </>
    )
}

import React from 'react';
import {Button} from 'antd';
import classes from './BuildControl.css'

export const BuildControl = (props) => {
    let removeBtnDisabled = null;
    let increase = null;
    if (props.content) {

    } else if (props.content === false) {

    }
    return (
        <div className={classes.BuildControl}>
            <Button disabled={props.disabled } danger
                    onClick={props.remove}
                    style={{marginLeft: '50px', backgroundColor: props.disabled ? '#ff999b' : null}}
                    type="primary">-</Button>

            {props.children}


            <Button onClick={props.add}
                    style={{marginRight: '50px'}}
                    type="primary">+</Button>
        </div>
    )
}
import React, {useState} from 'react';
import {Button, Input} from 'antd'
import classes from "../Packages/Packages.css";
import classesTwo from "./Comments.css";

export const Comments = (props) => {
    const [comments, setComments] = useState(null)
    const {TextArea} = Input;
    console.log(comments)
    const commentsHandler = (ev) => {
        setComments(ev.target.value)
    }
    const nextPageHandler = () => {
        props.history.replace({pathname: '/comentarii'});
    }
    const previousPageHandler = () => {
        props.history.replace({pathname: '/modifica-pachet'});
    }
    return (
        <div className={classesTwo.Comment}>
            <h2>9. Lasa un comentariu</h2>
            <div className={classesTwo.Text}>
                <TextArea style={{width: '400px', height: '200px'}} value={comments} onChange={commentsHandler}/>
            </div>

            <div className={classes.Pagination}>
                <Button status={false} onClick={previousPageHandler}>PAGINA ANTERIOARA</Button>
                <Button status={false} style={{width:'180px'}} onClick={nextPageHandler}>TRIMITE</Button>
            </div>
        </div>
    )
}